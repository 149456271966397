import Company, {State} from "../../domain/Companies/Company";

const companies: Company[] = [
    new Company(1, "Company 1", "Gala", "Netsuite", 12, State.ACTIVE),
    new Company(2, "Company 2", "Gala", "Salesforce", 12, State.ACTIVE),
    new Company(3, "Company 3", "Gala", "Dynamics", 12, State.ACTIVE),
    new Company(4, "Company 4", "Brad", "Salesforce", 12, State.INACTIVE),
    new Company(5, "Company 5", "Gala", "Hubspot", 12, State.ACTIVE),
    new Company(6, "Company 6", "Gordie", "Salesforce", 12, State.INACTIVE),
    new Company(7, "Company 7", "Zach", "Salesforce", 12, State.ACTIVE),
    new Company(8, "Company 8", "Gala", "Salesforce", 12, State.ACTIVE),
]

export default function useCompanies(): Company[] {
    return companies;
}
