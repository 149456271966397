import styled from "styled-components";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledAppBar = styled(MuiAppBar)`
  background-color: white !important;
  color: ${({theme}) => theme.palette.basic["primary-darker"]} !important;
  height: 110px !important;
  width: 100% !important;
  position: relative !important;
`

const StyledToolbar = styled(Toolbar)`
  margin: auto 0 !important;
`

const StyledTypography = styled(Typography)`
  text-align: center !important;
  width: 100% !important;
  font-weight: 300 !important;
  font-size: 30px !important;
`

const CompaniesHeader = (props: { "data-testid"?: string }) =>
    (<StyledAppBar {...props} elevation={0}>
            <StyledToolbar>
                <StyledTypography variant="h6" noWrap>
                    Companies
                </StyledTypography>
            </StyledToolbar>
        </StyledAppBar>
    );

export default CompaniesHeader;
