import React, {forwardRef} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {NavLink} from "react-router-dom";
import Box from "@mui/material/Box";
import Logo from "./drupe_logo.svg";
import LogoCondensed from "./drupe_logo_condensed.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiDrawer from "@mui/material/Drawer";
import {ReactComponent as CompaniesIcon} from "../icon_companies.svg"
import {SvgIcon, Theme} from "@mui/material";
import styled from "styled-components";

const drawerWidth = 240;

const openedMixin = (theme: Theme) => `
    width: ${drawerWidth}px;
    transition: ${theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
})};
    overflow-x: hidden;
    background-color: ${theme.palette.basic["primary-darker"]};
    color: white;
`;

const closedMixin = (theme: Theme) =>
    `
    transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })};
    overflow-x: hidden;
    width: calc(${theme.spacing(7)} + 1px);
    ${theme.breakpoints.up('sm')}: {
        width: calc(${theme.spacing(8)} + 1px),
    };
    background-color: ${theme.palette.basic["primary-darker"]};
    color: white;
`;

const StyledDrawer = styled(MuiDrawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${({theme, open}) => open && openedMixin(theme)};
  ${({theme, open}) => open && `& .MuiDrawer-paper {${openedMixin(theme)}}`};
  ${({theme, open}) => !open && closedMixin(theme)};
  ${({theme, open}) => !open && `& .MuiDrawer-paper {${closedMixin(theme)}}`};
`


const StyledDrawerHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65px;
`


const StyledListItem = styled(ListItem)`
  display: block;
`

const StyledListItemButton = styled(ListItemButton).withConfig({
    shouldForwardProp: (prop: any) => prop !== 'isOpen',
})<any>`
  min-height: 48px !important;
  justify-content: ${({isOpen}) => isOpen ? 'initial' : 'center'};
  padding-left: ${({theme}) => theme.spacing(2.5)};
  padding-right: ${({theme}) => theme.spacing(2.5)};
`

const StyledListItemIcon = styled(ListItemIcon).withConfig({
    shouldForwardProp: (prop: any) => prop !== 'isOpen',
})<any>`
  min-width: 0 !important;
  justify-content: center;
  margin-right: ${({theme, isOpen}) => isOpen ? theme.spacing(3) : 'auto'};,
`

const StyledDrawerArrowIcon = styled('div')`
  padding: 0;
  margin-left: auto;
  color: white;
`

interface MainMenuProps {
    isMenuOpen: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

const MainMenu = ({isMenuOpen, handleDrawerOpen, handleDrawerClose}: MainMenuProps) => {

    const CustomNavLink = forwardRef((props: { className: string, to: string }, ref: React.Ref<any>) => (
        <NavLink
            ref={ref}
            {...props}
            className={({isActive}) => (isActive ? props.className + ' Mui-selected' : props.className)}
            end
        />
    ));

    return (
        <StyledDrawer variant="permanent" open={isMenuOpen}>
            <StyledDrawerHeader>
                {isMenuOpen && (
                    <>
                        <StyledDrawerArrowIcon onClick={handleDrawerClose}>
                            <ChevronLeftIcon/>
                        </StyledDrawerArrowIcon>
                        <Box
                            component="img"
                            alt="Drupe logo."
                            src={Logo}
                        />
                    </>
                )
                }
                {!isMenuOpen && (
                    <>
                        <StyledDrawerArrowIcon onClick={handleDrawerOpen}>
                            <ChevronRightIcon/>
                        </StyledDrawerArrowIcon>
                        <Box
                            component="img"
                            alt="Drupe logo."
                            src={LogoCondensed}
                        />
                    </>
                )
                }
            </StyledDrawerHeader>
            <List>
                <StyledListItem disablePadding>
                    <StyledListItemButton
                        component={CustomNavLink}
                        to="/companies"
                        isOpen={isMenuOpen}
                    >
                        <StyledListItemIcon isOpen={isMenuOpen}>
                            <SvgIcon component={CompaniesIcon}/>
                        </StyledListItemIcon>
                        {isMenuOpen && <ListItemText primary="Companies"/>}
                    </StyledListItemButton>
                </StyledListItem>
            </List>
        </StyledDrawer>

    )
}

export default MainMenu;
