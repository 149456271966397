import React, {ReactElement} from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";


const StyledHeaderBoxWrapper = styled(Box)`
  height: 110px;
`

const StyledMainContentBoxWrapper = styled(Box)`
  flex-grow: 1;
  p: 3;
  width: 100%;
  height: calc(100% - 110px);
  padding: 0;
`


type Props = {
    children: ReactElement[]
}

type PropsPart = {
    children: ReactElement
}


const Page = ({children}: Props) => (
    <>
        {children}
    </>
)

Page.Header = (props: PropsPart) => (
    <StyledHeaderBoxWrapper>
        {props.children}
    </StyledHeaderBoxWrapper>
);

Page.Content = (props: PropsPart) => (
    <StyledMainContentBoxWrapper component="main">
        {props.children}
    </StyledMainContentBoxWrapper>
);

export default Page;
