import * as React from "react";
import styled from "styled-components";
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import useCompanies from "./hooks/useCompanies";
import {State} from "../domain/Companies/Company";
import {ActiveCompanyIcon, InactiveCompanyIcon} from "./CompanyStatusComponent";

const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'id',
            flex: 1,
            headerName: 'Company ID',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'csa',
            flex: 1,
            headerName: 'CSA',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'crm',
            flex: 1,
            headerName: 'CRM',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'numberOfActiveUsers',
            flex: 1,
            headerName: 'Active users',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'state',
            flex: 1,
            headerName: 'State',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params: GridCellParams) => {
                return params.value === State.ACTIVE ? (<ActiveCompanyIcon/>) : (<InactiveCompanyIcon/>)
            },
        }
    ]
;


const StyledBoxDataGridContainer = styled(Box)`
  width: 100%;
  background-color: ${({theme}) => theme.palette.grayscale.bg};
  padding: 0;
  height: 100%;
`;


const StyledDataGrid = styled(DataGrid)`
  background-color: ${({theme}) => theme.palette.grayscale.bg};
  padding: 30px 22px;
  border: none !important;

  & .MuiDataGrid-columnHeaders {
    background-color: ${({theme}) => theme.palette.grayscale["gray-lighter"]};
  }

  & .MuiDataGrid-columnHeaderTitle {
    font-weight: ${({theme}) => theme.typography.Small.bold.left["font-weight"]};
    font-size: ${({theme}) => theme.typography.Small.bold.left["font-size"]};
    line-height: ${({theme}) => theme.typography.Small.bold.left["line-height"]};
  }

  & .MuiDataGrid-virtualScroller {
    background-color: white;
  }

  & .MuiDataGrid-footerContainer {
    display: none;
  }

  & .MuiDataGrid-row:hover {
    background-color: ${({theme}) => theme.palette.basic.primary_20} !important;
  }
`;

const CompaniesMainContent = (props: { "data-testid"?: string }) => {
    const companies = useCompanies();
    return (
        <StyledBoxDataGridContainer {...props}>
            {/* columnBuffer is needed to avoid virtualization of the page which makes the test not render all the columns. Check https://stackoverflow.com/questions/65669707/problem-testing-material-ui-datagrid-with-react-testing-library  */}
            <StyledDataGrid
                rows={companies}
                columns={columns}
                columnBuffer={6}
                disableRowSelectionOnClick
            />
        </StyledBoxDataGridContainer>
    )
};

export default CompaniesMainContent;
