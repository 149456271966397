import {useState} from 'react';


interface TokenInformation {
    error?: string;
    token?: string;
    userId?: string;
}

export const getNewToken = async (username: string, password: string): Promise<TokenInformation> => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    try {
        const response = await fetch('http://localhost:8000/api/2/login/', {
            method: 'POST',
            body: formData
        });

        if (response.status !== 200) {
            return {error: "Unknown login error. Please contact support."}
        }

        const body = await response.json();
        if (body.status === "error_authentication_failed") {
            return {error: "Invalid username or password"}
        }

        return {token: body.token, userId: body.user_id}
    } catch (error: any) {
        console.log(error)
        return {error: "Unknown login error. Please contact support."}
    }
}

export default function useToken() {
    const getToken = (): TokenInformation => {
        const tokenInformationString = sessionStorage.getItem('token');
        if (tokenInformationString) {
            return JSON.parse(tokenInformationString);
        }
        return {token: undefined, error: undefined};
    };

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [tokenInformation, setTokenInformation] = useState<TokenInformation>(getToken());

    const requestNewToken = async () => {
        const newTokenInformation = await getNewToken(username, password);
        // If the user reloads we don't want to show again the same error over and over
        const tokenInformationWithoutError = {...newTokenInformation, error: undefined}
        sessionStorage.setItem('token', JSON.stringify(tokenInformationWithoutError));
        setTokenInformation(newTokenInformation)
    }

    return {
        tokenInformation,
        requestNewToken,
        setUsername,
        setPassword
    }
}
