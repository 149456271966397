import {createTheme} from '@mui/material/styles';


const OtterTheme = createTheme({
    palette: {
        "basic": {
            "accent": "#f3b61f",
            "accent_20": "rgba(243, 182, 31, 0.2)",
            "primary": "#4a90e2",
            "primary-dark": "#274060",
            "primary-darker": "#1b2845",
            "primary_20": "rgba(74, 144, 226, 0.2)"
        },
        "grayscale": {
            "bg": "#f4f4f4",
            "gray-dark": "#3c3c3c",
            "gray-default": "#979797",
            "gray-light": "#cecece",
            "gray-lighter": "#f0f0f0",
            "white": "#ffffff",
            "white_30": "rgba(255, 255, 255, 0.3)"
        },
        "state": {
            "alert": "#c23615",
            "success": "#bbd8b3"
        },
        "globalSizes": {
            "menuWidth": "239px",
            "condensedMenuWidth": "65px"
        }
    },
    typography: {
        "Large": {
            "bold": {
                "center": {
                    "font-family": "'Noto Sans'",
                    "font-size": "16px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "700"
                },
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "16px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "700"
                }
            },
            "regular": {
                "center": {
                    "font-family": "'Noto Sans'",
                    "font-size": "16px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "400"
                },
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "16px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "400"
                }
            }
        },
        "Medium": {
            "bold": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "14px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "700"
                }
            },
            "italic": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "14px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "400",
                    "font-style": "italic"
                }
            },
            "regular": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "14px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "400"
                }
            }
        },
        "Small": {
            "bold": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "12px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "700"
                }
            },
            "italic": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "12px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "400",
                    "font-style": "italic"
                }
            },
            "medium": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "12px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "500"
                }
            },
            "regular": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "12px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "400"
                }
            }
        },
        "X-Large": {
            "semiBold": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "20px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "600"
                }
            }
        },
        "XX-Large": {
            "light": {
                "center": {
                    "font-family": "'Noto Sans'",
                    "font-size": "30px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "300"
                }
            },
            "medium": {
                "left": {
                    "font-family": "'Noto Sans'",
                    "font-size": "30px",
                    "letter-spacing": "0px",
                    "line-height": "100%",
                    "font-weight": "500"
                }
            }
        },
        "Logo": {
            "font-family": "'Martel Sans'",
            "font-size": "42px",
            "letter-spacing": "0px",
            "line-height": "40px",
            "font-weight": "275"
        }
    },
});


declare module '@mui/material/styles' {
    interface Palette {
        basic: {
            "accent": string,
            "accent_20": string,
            "primary": string,
            "primary-dark": string,
            "primary-darker": string,
            "primary_20": string
        },
        grayscale: {
            "bg": string,
            "gray-dark": string,
            "gray-default": string,
            "gray-light": string,
            "gray-lighter": string,
            "white": string,
            "white_30": string
        },
        state: {
            'alert': string,
            'success': string
        },
        'globalSizes': {
            'menuWidth': string,
            'condensedMenuWidth': string
        }
    }

    interface PaletteOptions {
        basic: {
            "accent": string,
            "accent_20": string,
            "primary": string,
            "primary-dark": string,
            "primary-darker": string,
            "primary_20": string
        },
        grayscale: {
            "bg": string,
            "gray-dark": string,
            "gray-default": string,
            "gray-light": string,
            "gray-lighter": string,
            "white": string,
            "white_30": string
        },
        state: {
            'alert': string,
            'success': string
        },
        'globalSizes': {
            'menuWidth': string,
            'condensedMenuWidth': string
        }
    }
}


declare module '@mui/material/styles/createTypography' {
    interface TypographyOptions {
        Large: {
            bold: {
                center: TypographyStyleOptions,
                left: TypographyStyleOptions,
            },
            regular: {
                center: TypographyStyleOptions,
                left: TypographyStyleOptions,
            },
        },
        Medium: {
            bold: {
                left: TypographyStyleOptions
            },
            italic: {
                left: TypographyStyleOptions
            },
            regular: {
                left: TypographyStyleOptions
            }
        },
        Small: {
            bold: {
                left: TypographyStyleOptions
            },
            italic: {
                left: TypographyStyleOptions
            },
            medium: {
                left: TypographyStyleOptions
            },
            regular: {
                left: TypographyStyleOptions
            }
        },
        "X-Large": {
            semiBold: {
                left: TypographyStyleOptions
            },
        },
        "XX-Large": {
            light: {
                center: TypographyStyleOptions
            },
            medium: {
                left: TypographyStyleOptions
            },
        },
        Logo: TypographyStyleOptions
    }
}

export default OtterTheme;
