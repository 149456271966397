import * as React from 'react';
import {useEffect} from 'react';
import {useTheme} from '@mui/material/styles';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MainMenu from "./common/components/MainMenu/MainMenu";
import CompaniesPage from "./CompaniesPage/CompaniesPage";
import Login from "./Login/Login";
import ProtectedRoute from "./common/components/ProtectedRoute";


const StyledRootBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledPageBox = styled(Box)<any>`
  width: ${({
              theme,
              open
            }) => `calc(100% - ${open ? theme.palette.globalSizes.menuWidth : theme.palette.globalSizes.condensedMenuWidth})`};
  height: 100%;
`;


export default function App() {
    const theme = useTheme();
    const [open, setOpen] = React.useState<boolean>(false);
    const shouldHideMenu = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (shouldHideMenu) {
            setOpen(false);
        } else {
            setOpen(true);
        }

    }, [shouldHideMenu])


    const handleDrawerOpen = () => setOpen(true);

    const handleDrawerClose = () => setOpen(false);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={(
                    <Login/>
                )}/>
                {/* This route duplication is temporal until we have a dashboard */}
                <Route path="/" element={(
                    <ProtectedRoute>
                        <StyledRootBox>
                            <MainMenu isMenuOpen={open} handleDrawerOpen={handleDrawerOpen}
                                      handleDrawerClose={handleDrawerClose}/>
                            <StyledPageBox theme={theme} open={open}>
                                <CompaniesPage/>
                            </StyledPageBox>
                        </StyledRootBox>
                    </ProtectedRoute>
                )}/>
                <Route path="/companies" element={(
                    <ProtectedRoute>
                        <StyledRootBox>
                            <MainMenu isMenuOpen={open} handleDrawerOpen={handleDrawerOpen}
                                      handleDrawerClose={handleDrawerClose}/>
                            <StyledPageBox theme={theme} open={open}>
                                <CompaniesPage/>
                            </StyledPageBox>
                        </StyledRootBox>
                    </ProtectedRoute>
                )}/>
            </Routes>
        </Router>
    );
}
