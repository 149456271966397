import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider as MaterialThemeProvider} from '@mui/material/styles';
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components';
import './index.css';
import App from './App';
import theme from './theme';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <StyledComponentsThemeProvider theme={theme}>
            <MaterialThemeProvider theme={theme}>
                <CssBaseline/>
                <App/>
            </MaterialThemeProvider>
        </StyledComponentsThemeProvider>
    </React.StrictMode>
);
