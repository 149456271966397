import * as React from "react";
import Page from "../common/components/Page";
import CompaniesMainContent from "./CompaniesMainContent";
import CompaniesHeader from "./CompaniesHeader";

const CompaniesPage = () => (
    <Page>
        <Page.Header>
            <CompaniesHeader data-testid="companies-header"/>
        </Page.Header>
        <Page.Content>
            <CompaniesMainContent data-testid="companies-main-content"/>
        </Page.Content>
    </Page>
);

export default CompaniesPage;
