import * as React from 'react';
import {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import useToken from "../hooks/useToken";

const ProtectedRoute = ({children}: { children: ReactElement | ReactElement[] }) => {
    const {tokenInformation} = useToken();
    if (!tokenInformation.token) {
        return <Navigate to="/login" replace/>;
    }

    return (
        <>
            {children}
        </>
    );
};

export default ProtectedRoute;
