export enum State {
    ACTIVE,
    INACTIVE
}

export default class Company {
    constructor(
        public id: number,
        public name: string,
        public csa: string,
        public crm: string,
        public numberOfActiveUsers: number,
        public state: State,
    ) {
    }
}
