import * as React from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import {Navigate} from 'react-router-dom';
import useToken from "../common/hooks/useToken";
import Logo from "../common/components/MainMenu/drupe_logo.svg";

const StyledRootContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.palette.basic["primary-darker"]};
`;

const StyledLoginContainer = styled(Box)`
  width: 245px;
  height: 310px;
`;


const StyledLogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

const StyledLoginInput = styled('input')`
  width: 100%;
  display: block;
  margin: 10px 0 0 0;
  border: 0;
  background: 0;
  padding: 11px 10px 9px 10px;
  border-radius: 0;
  background: #fff;
`;

const StyledLoginInputContainer = styled('form')`
  position: relative;
  margin-bottom: 10px;
  padding: 0;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 20px;
`;

const StyledLoginButton = styled('button')`
  background: #4a90e2;
  color: #fff;
  border: 1px solid #3d82d3;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
  width: 100%;
  height: 35px;
`;

const StyledErrorMessage = styled('p')`
  color: red;
`

const Login = () => {
    const {setUsername, setPassword, requestNewToken, tokenInformation} = useToken();

    if (tokenInformation.token) {
        return <Navigate to="/companies" replace/>;
    }

    return (
        <StyledRootContainer>
            <StyledLoginContainer>
                <StyledLogoContainer>
                    <Box component="img"
                         alt="Drupe logo."
                         src={Logo}/>
                </StyledLogoContainer>
                <StyledLoginInputContainer className="input-container" onSubmit={(e) => {
                    e.preventDefault();
                    void requestNewToken();
                }}>
                    <StyledLoginInput type="text" autoCapitalize="off" autoCorrect="off" required
                                      data-testid="username-input"
                                      onChange={e => setUsername(e.target.value)}
                                      placeholder="Email address or Username"/>
                    <StyledLoginInput type="password" placeholder="Password" required data-testid="password-input"
                                      onChange={e => setPassword(e.target.value)}
                    />
                    <input type="submit" hidden/>
                </StyledLoginInputContainer>
                {tokenInformation.error && <StyledErrorMessage>{tokenInformation.error}</StyledErrorMessage>}
                <StyledLoginButton data-testid="login-button" onClick={() => requestNewToken()}>Log
                    in</StyledLoginButton>
            </StyledLoginContainer>
        </StyledRootContainer>
    );
}
export default Login;
